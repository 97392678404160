<template>
<v-app>
    <update-notification></update-notification>
    <sidebar v-if="authStore.isAuthenticated"></sidebar>
    <v-main>
        <router-view />
    </v-main>
</v-app>
</template>

<script>
import Sidebar from './components/Sidebar.vue'
import UpdateNotification from './components/UpdateNotification.vue'

export default {
    components: {
        Sidebar,
        UpdateNotification
    },
    name: 'App',

    data: () => ({
        //
    }),
}
</script>

<style>
@import url('@/assets/styles.css');
</style>
