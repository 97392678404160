<template>
<v-navigation-drawer app class="base-inverted" permanent>
    <v-sheet class="text-center pa-3">
        <v-avatar size="120">
            <v-img src="@/assets/logo.png"></v-img>
        </v-avatar>
        <div class="text-center"><label class="text-caption">{{require('../../package.json').version}}</label></div>
        <v-row align="center" dense>
            <v-col cols="10"><label class="text-body-1">Welcome {{name}}</label></v-col>
            <v-col cols="2"><v-btn flat icon="mdi-power" @click="logout()"></v-btn></v-col>
        </v-row>
    </v-sheet>
    <v-divider></v-divider>
    <v-list density="compact" nav>
        <v-list-item  v-for="menu in filteredMenus" :key="menu.name" :prepend-icon="menu.icon" :title="menu.name" :to="menu.page">
        </v-list-item>
    </v-list>
</v-navigation-drawer>
</template>

<script>
import AppConstants from '@/utils/AppConstants'
export default {
    data() {
        return {
            menus: Object.values(AppConstants.MENU)
        }
    },
    computed: {
        name() {
            let user = this.authStore.loggedInUser
            return `${user.firstName} ${user.lastName}`
        },
        filteredMenus(){
            return this.menus.filter(rec => this.isAllowed(rec.permission))
        }
    }
}
</script>

<style scoped>

</style>
