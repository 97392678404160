export default Object.freeze({
    MENU: {
        INVOICES: {
            name: "Invoices",
            icon: "mdi-file-chart",
            page: "/invoices",
            API_ENDPOINT: "/api/invoices",
            permission: "product_list"
        },
        CUSTOMERS: {
            name: "Customers",
            icon: "mdi-account-supervisor-circle",
            page: "/customers",
            API_ENDPOINT: "/api/customers",
            permission: "customer_list"
        },
        EMPLOYEES: {
            name: "Employees",
            icon: "mdi-account",
            page: "/employees",
            API_ENDPOINT: "/api/employees",
            permission: "user_list"
        },
        PO: {
            name: "Purchase Orders",
            icon: "mdi-book-check",
            page: "/purchaseOrders",
            API_ENDPOINT: "/api/po",
            permission: "po_list"
        },
        PRODUCTS: {
            name: "Products",
            icon: "mdi-cube-scan",
            page: "/products",
            API_ENDPOINT: "/api/products",
            permission: "product_list"
        },
        /* REPORTS: {
            name: "Reports",
            icon: "mdi-finance",
            page: "/"
        }, */
        ROLES: {
            name: "Roles",
            icon: "mdi-shield-account",
            page: "/roles",
            API_ENDPOINT: "/api/roles",
            permission: "role_list"
        },
        GRADES: {
            name: "Grade Configs",
            icon: "mdi-perspective-more",
            page: "/gradeConfigs",
            API_ENDPOINT: "/api/grade-configs",
            permission: "gradeConfig_list"
        }
    },
    DEFAULT:{
        EMPTY_STRING: "",
        PIPE_SEPARATOR: " | ",
        DATE_FORMAT: "YYYY-MM-DD",
        DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
        COMMA_SEPARATOR: ", ",
        COMMA_SPACE_SEPARATOR: ", ",
        EDIT_INDEX: -1,
        GST_STATE_CODE: 27,
        UNIT: "KGS",
        RATE: 0
    },
    TABLE:{
        ALIGNMENT:{
            LEFT: "left",
            CENTER: "center",
            RIGHT: "right"
        },
        DATA_TYPE:{
            DATE: "date",
            BOOLEAN: "boolean"
        }
    },

    PO:{
        APPLICABLE_TO:{
            ALL: "all",
            SPECIFIC: "specific"
        }
    },
    STATUS:{
        UNAUTHENTICATED: 401,
        UNAUTHORISED: 403
    },

    APP_DATE_FORMATE: "DD/MM/YYYY",
    APP_DATE_TIME_FORMAT: "DD/MM/YYYY HH:mm:ss",

    LS_LOGGED_IN_USER: 'loggedInUser',
    LS_TOKEN: "token",

    API_URL: process.env.VUE_APP_API_SERVER,
    API_PRODUCTS: '/api/products',
    API_CUSTOMERS: '/api/customers',
    API_PO: '/api/po',
    API_INVOICES: '/api/invoices',
    API_ROLES: '/api/roles',
    API_EMPLOYEES: '/api/employees',
    API_SECURITY: '/api/security',
    API_FORCE_PASSWORD_RESET: '/api/security/password-reset',
    API_GRADES: '/api/grade-configs',
})