<script>
import axios from 'axios'
import moment from 'moment'
import {
    authStore
} from '@/store/authstore'

axios.interceptors.request.use(req => {
  if(localStorage.getItem('token')) {
    req.headers['Authorization'] = localStorage.getItem('token');
  }
  return req;
});

export default {
    data() {
        return {
            pageError: '',
            pageLoading: false,
            authStore: authStore()
        }
    },
    methods: {
        randomColor() {
            return "#" + Math.floor(Math.random() * 0x1000000).toString(16)
        },
        getCommonHeader() {
            return {
                headers: {
                    abc: 1,
                    authorization: localStorage.getItem('token')
                }
            }
        },
        async get(url) {
            try {
                return (await axios.get(this.$const.API_URL + url)).data
            } catch (error) {
                this.handleUnAuthorised(error)
            }
        },
        async post(url, payload) {
            try {
                return (await axios.post(this.$const.API_URL + url, payload)).data
            } catch (error) {
                this.handleUnAuthorised(error)
            }
        },
        async put(url, payload) {
            try {
                return (await axios.put(this.$const.API_URL + url, payload)).data
            } catch (error) {
                this.handleUnAuthorised(error)
            }
        },
        async patch(url, payload) {
            try {
                return (await axios.patch(this.$const.API_URL + url, payload)).data
            } catch (error) {
                this.handleUnAuthorised(error)
            }
        },
        async delete(url) {
            try {
                if (confirm("Are you sure you want to delete this item?")) {
                    return (await axios.delete(this.$const.API_URL + url)).data
                }
            } catch (error) {
                this.handleUnAuthorised(error)
            }
        },
        async downloadFileFromApi(url){
            return axios({
                    url: this.$const.API_URL + url,
                    method: 'GET',
                    responseType: 'blob',
                })
        },
        formatDate(date, format = this.$const.APP_DATE_FORMATE) {
            if (!date)
                return '';
            if ((typeof date) == "string") {
                return moment(new Date(date)).format(format)
            }
            return moment(date).format(format)
        },
        formatCurrency(number) {
            return parseFloat(number.toFixed(2))
        },
        formatNumberWithDecimalCurrency(number, decimals) {     
            return parseFloat(number.toFixed(decimals))
        },
        padNumberWithZero(number, decimals=2){
            var formattedNumber = parseFloat(number).toFixed(decimals); // Set maximum decimal places to 2
            var parts = formattedNumber.split(".");
            if (parts.length === 1) {
                // If the number doesn't have any decimal places
                formattedNumber = formattedNumber.padEnd(formattedNumber.length + decimals, "0");
            } else if (parts[1].length < 2) {
                // If the number has less than 2 digits after the decimal point
                parts[1] = parts[1].padEnd(decimals, "0");
                formattedNumber = parts.join(".");
        }
  
  return formattedNumber;
        },
        handleError(error) {
            console.error(error);
            this.pageError = error
        },
        print(divName) {
            var printContents = document.getElementById(divName).innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        },
        searchObject(obj, searchTerm) {
            for (let key in obj) {
                if (typeof obj[key] === 'object' && obj[key]) {
                    if(this.searchObject(obj[key], searchTerm)){
                        return true
                    }
                } else if (key != '_id' && obj[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                    return true;
                }
            }
            return false;
        },
        logout() {
            this.authStore.logout()
            this.$router.push('/')
        },
        handleUnAuthorised(error){
            if(error.response?.data?.error?.statusCode == this.$const.STATUS.UNAUTHENTICATED && error.response?.data?.error?.errorCode == "UNAUTHENTICATED"){
                alert("Session time out, please login again");
                this.logout();
            }else{
                throw error
            }
        },
        isAllowed(permission){
            return this.authStore?.loggedInUser?.permissions.includes(permission)
        }
    },
}
</script>
