import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import AppConstants from './utils/AppConstants'
import GlobalMixins from './utils/GlobalMixins.vue'
import JsonExcel from "vue-json-excel3";

loadFonts()

const pinia = createPinia()
const app = createApp(App)
  .use(router)
  .use(vuetify)

app.use(pinia)
app.mixin(GlobalMixins)
app.component("downloadExcel", JsonExcel)
app.config.globalProperties.$const = AppConstants
app.mount('#app')