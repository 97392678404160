import AppConstants from '@/utils/AppConstants';
import { defineStore } from 'pinia'
import VueJwtDecode from "vue-jwt-decode";

export const authStore = defineStore('authStore', {
    state: () => ({loggedInUser: undefined, token: undefined}),
    getters: {
      isAuthenticated: (state) => {
        let persistedToken = localStorage.getItem(AppConstants.LS_TOKEN)
        if(state.token){
          return true
        }else if(persistedToken){
              state.token = persistedToken
              state.loggedInUser = updateLoggedInUserInfo(state.token)
              return true
        }
        return false
      },
    },
    actions: {
      updateToken(token){
        this.token = token
        localStorage.setItem(AppConstants.LS_TOKEN, token)
        this.loggedInUser = updateLoggedInUserInfo(token)
      },
      logout(){
        this.loggedInUser =  undefined
        this.token = undefined
        localStorage.removeItem(AppConstants.LS_LOGGED_IN_USER)
        localStorage.removeItem(AppConstants.LS_TOKEN)
      }
    },
  })
  function updateLoggedInUserInfo(token) {
    let loggedInUser = VueJwtDecode.decode(token)
    localStorage.setItem(AppConstants.LS_LOGGED_IN_USER, JSON.stringify(loggedInUser))
    return loggedInUser
  }
  