import { authStore } from '@/store/authstore'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/products/:id',
    name: 'productEdit',
    component: () => import('../views/ProductEdit.vue')
  },
  {
    path: '/product/:id',
    name: 'productView',
    component: () => import('../views/ProductView.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    component: () => import('../views/Customers.vue')
  },
  {
    path: '/customers/:id',
    name: 'customerEdit',
    component: () => import('../views/CustomerEdit.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/Employees.vue')
  },
  {
    path: '/employees/:id',
    name: 'employeeEdit',
    component: () => import('../views/EmployeeEdit.vue')
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/Invoices.vue')
  },
  {
    path: '/invoices/:id',
    name: 'invoiceEdit',
    component: () => import('../views/InvoiceEdit.vue')
  },
  {
    path: '/purchaseOrders',
    name: 'purchaseOrders',
    component: () => import('../views/PurchaseOrders.vue')
  },
  {
    path: '/purchaseOrders/:id',
    name: 'purchaseOrderEdit',
    component: () => import('../views/PurchaseOrderEdit.vue')
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('../views/Roles.vue')
  },
  {
    path: '/roles/:id',
    name: 'roleEdit',
    component: () => import('../views/RoleEdit.vue')
  },
  {
    path: '/role/:id',
    name: 'roleView',
    component: () => import('../views/RoleView.vue')
  },
  {
    path: '/print/:document/:id',
    name: 'printPage',
    component: () => import('../views/PrintPage.vue')
  },
  {
    path: '/gradeConfigs',
    name: 'gradeConfigs',
    component: () => import('../views/GradeConfigs.vue')
  },
  {
    path: '/gradeConfigs/:id',
    name: 'gradeConfigEdit',
    component: () => import('../views/GradeConfigEdit.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  const store = authStore()
  if(to.path == '/')
    next()
  else if(store.isAuthenticated)
    next()
  else
    next('/')
  
})

export default router
